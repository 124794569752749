import * as React from "react";

import SEO from "../../components/seo";

export default function GetInTouchPage() {
  return (
    <>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SEO title="Get in touch / Thanks" />
      <h1>Thanks for dropping me a line</h1>

      <p>I'll try to get back to you as soon as possible.</p>
    </>
  );
}
